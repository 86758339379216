import { render, staticRenderFns } from "./jump-menu.svg.vue?vue&type=template&id=36bf6751&scoped=true&"
import script from "./jump-menu.svg.vue?vue&type=script&lang=js&"
export * from "./jump-menu.svg.vue?vue&type=script&lang=js&"
import style0 from "./jump-menu.svg.vue?vue&type=style&index=0&id=36bf6751&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bf6751",
  null
  
)

export default component.exports