<template>
  <g>
    <icon>
      <circle cx="0" cy="-12" r="5" stroke-width="1" fill="#ffffff" stroke="#ffffff44" />
      <rect x="-5" y="-2" width="10" height="20" rx="4" ry="4" stroke-width="1" fill="#ffffff" stroke="#ffffff44"  />
    </icon>
  </g>
</template>

<script>
import Icon from './_icon-container.svg'

export default {
  name: 'InfoIcon',
  components: {
    Icon
  },
  data () {
    return {
    }
  }
}
</script>
