<template>
  <g>
    <icon>
      <g transform="scale(0.03) translate(-448 -512)">
        <path fill="#ffffff" d="M447.938 350C358.531 350 286 422.531 286 512c0 89.375 72.531 162.062 161.938 162.062 89.438 0 161.438-72.688 161.438-162.062C609.375 422.531 537.375 350 447.938 350zM772.625 605.062l-29.188 70.312 52.062 102.25 6.875 13.5-72.188 72.188L611.75 807.375l-70.312 28.875L505.75 945.5l-4.562 14.5H399.156L355 836.688l-70.312-29-102.404 51.938-13.5 6.75-72.156-72.125 55.875-118.5-28.969-70.25L14.469 569.875 0 565.188V463.219L123.406 419l28.969-70.188-51.906-102.469-6.844-13.438 72.062-72.062 118.594 55.844 70.219-29.031 35.656-109.188L394.75 64h102l44.188 123.469 70.125 29.031L713.5 164.53099999999995l13.625-6.844 72.125 72.062-55.875 118.406L772.25 418.5l109.375 35.656L896 458.75v101.938L772.625 605.062z"/>
      </g>
    </icon>
  </g>
</template>

<script>
import Icon from './_icon-container.svg'

export default {
  name: 'GearIcon',
  components: {
    Icon
  },
  data () {
    return {
    }
  }
}
</script>
