<template>
  <g>
    <g stroke="#ffffff" stroke-width="0" fill="url(#starGradient)">
        <circle cx="0" cy="154" r="8" />
    </g>
    <circle cx="0" cy="154" r="16" stroke-width="0" fill="url(#glowingGradient)" />
  </g>
</template>
<script>

export default {
  name: 'CompassSun'
}
</script>
