<template>
  <g>
    <g transform="scale(0.0025)">
      <circle cx="0" cy="0" r="4000" stroke-width="0" fill="#c3b3da00" />
      <g v-if="showEngines && (graphicsQuality > 1)">
        <ship-engines />
      </g>
      <g transform="translate(-2300,-2300)">
        <path fill="none" stroke-width="50" stroke="#ffffff" class="fil0" d="M2303 0c0,0 0,0 0,0 233,0 443,102 598,259 151,152 249,355 249,588 0,332 0,663 0,995l0 100c170,37 340,84 510,138 12,-109 63,-204 137,-279 87,-88 204,-146 335,-146 0,0 0,0 0,0 130,0 247,58 334,146 85,85 140,198 140,328 0,117 0,234 0,351 0,117 0,234 0,351 -67,27 -135,50 -202,68l12 72c4,27 8,53 12,79 9,51 17,101 25,150 -53,27 -106,50 -161,62 -53,13 -107,17 -160,17 -54,0 -108,-4 -161,-17 -54,-12 -107,-35 -161,-62 8,-49 17,-99 25,-151 4,-25 8,-51 13,-78 3,-24 7,-48 11,-72 -67,-18 -134,-41 -201,-68 0,-117 0,-234 0,-351l0 -96c-170,-54 -339,-100 -508,-137l0 589c-135,56 -269,102 -404,136l13 81c6,42 13,84 20,125 13,82 27,163 40,243 -86,42 -171,79 -258,99 -85,20 -172,27 -258,27 -86,0 -173,-7 -258,-27 -87,-20 -173,-57 -258,-99 13,-80 26,-161 40,-243 7,-41 13,-83 20,-125 4,-27 8,-54 13,-81 -135,-34 -269,-81 -404,-136l0 -589c-169,37 -338,83 -508,137l0 96c0,117 0,234 0,351 -67,27 -134,50 -201,68l11 72c4,27 8,53 13,79 8,51 16,101 25,150 -54,27 -107,50 -161,62 -53,13 -107,17 -161,17 -54,0 -108,-4 -161,-17 -54,-12 -107,-35 -161,-62 9,-49 17,-99 26,-151 4,-25 8,-51 12,-78 4,-24 8,-48 11,-72 -67,-18 -134,-41 -201,-68 0,-117 0,-234 0,-351 0,-117 0,-234 0,-351 0,-130 55,-243 139,-328 87,-88 205,-146 335,-146 0,0 0,0 0,0 130,0 248,58 335,146 74,75 125,170 137,279 170,-54 340,-101 510,-138l0 -100c0,-332 0,-663 0,-995 0,-233 97,-436 249,-589 155,-156 365,-258 598,-258z"/>
      </g>
    </g>
  </g>
</template>

<script>
import { mapGetters } from 'vuex'
import ShipEngines from './animations/ship-engines.svg'

export default {
  name: 'ShipOutline',
  components: {
    ShipEngines,
  },
  props: {
    showEngines: {
      default: false
    }
  },
  computed: {
    ...mapGetters('Config', [
      'graphicsQuality'
    ]),
  },
}
</script>
