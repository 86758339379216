<template>
  <g>
    <g stroke="#ffffff" stroke-width="0" fill="#ffffff32">
        <circle cx="0" cy="120" r="8" fill="#ffffff00" />
        <circle cx="0" cy="120" r="6" />
    </g>
  </g>
</template>
<script>

export default {
  name: 'CompassPlanet'
}
</script>
