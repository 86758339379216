<template>
  <g>
      <icon>
    <g stroke-linejoin="round" stroke-width="3" stroke="#ffffff" transform="scale(0.4) translate(-37 -37)">
      <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" fill="#ffffff" />
      <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" fill="none" />
    </g>
    </icon>
  </g>
</template>

<script>

import Icon from './_icon-container.svg'

export default {
  name: 'AvatarIcon',
  components: {
      Icon
  },
  data () {
    return {
    }
  }
}
</script>
