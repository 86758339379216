<template>
  <g>
    <icon>
      <g fill="#aaaaaa" stroke="#ffffff" stroke-width="1">
        <rect x="-12" y="-11" rx=2 ry=2 width="24" height="3" />
        <rect x="-12" y="-1" rx=2 ry=2 width="24" height="3" />
        <rect x="-12" y="9" rx=2 ry=2 width="24" height="3" />
      </g>
    </icon>
  </g>
</template>

<script>
import Icon from './_icon-container.svg'

export default {
  name: 'HamburgerIcon',
  components: {
    Icon
  },
  data () {
    return {
    }
  }
}
</script>
